<template>
  <div
    class="cpv-pdf-viewport"
    :class="{ 'cpv-pdf-viewport--adding-signature-area': !!addSignatureAreaToPage }"
  >
    <div
      v-for="page in pages"
      :key="`page-${page.pageNumber}`"
      :ref="`page-${page.pageNumber}`"
      class="cpv-pdf-viewport__page"
      :class="{
        'cpv-pdf-viewport__page--current': currentPage === page.pageNumber && isPagination,
        'cpv-pdf-viewport__page--link': isPagination,
      }"
      @click="$emit('page-click', page.pageNumber)"
    >
      <canvas
        :ref="`canvas-${page.pageNumber}`"
        class="cpv-pdf-viewport__page__canvas"
        width="100%"
      />
      <template v-if="canSetSignatureArea || canSign">
        <div
          v-for="(sigArea, ix) in signatureAreasComputed
            .filter((s) => s.page === page.pageNumber && !s.signature)"
          :key="`sigArea-${page.pageNumber}-${ix}`"
          :ref="`sigArea-${sigArea.id}`"
          class="cpv-pdf-viewport__page__signature-area"
          :class="{ 'cpv-pdf-viewport__page__signature-area--new': !sigArea.id }"
          :style="{
            top: `${sigArea.y || 25 * scale}%`,
            left: `${sigArea.x || 25 * scale}%`,
            width: `${sigArea.width || 30 * scale}%`,
            height: `${sigArea.height || 10 * scale}%`,
          }"
          @click="canSign
            ? $emit('signing', getSignatureArea(sigArea))
            : editSignatureArea(sigArea.id, $event)"
        >
          <button
            v-if="sigArea.id && currentSigArea === sigArea.id"
            class="cpv-pdf-viewport__page__signature-area__delete"
            @click.stop.prevent="deleteSignatureArea(sigArea.id)"
          >
            Delete
          </button>
          <div
            v-if="currentSigArea === sigArea.id || sigArea.id === 0"
            class="buttons"
          >
            <button
              class="cancel"
              @click.stop="cancelSignatureArea"
            >
              Cancel
            </button>
            <button
              class="save"
              @click.stop="saveSignatureArea"
            >
              Save
            </button>
          </div>
        </div>
        <div
          v-for="(sig, ix) in signatureAreasComputed
            .filter((s) => s.page === page.pageNumber && !!s.signature)"
          :key="`sig-${page.pageNumber}-${ix}`"
          class="cpv-pdf-viewport__page__signature"
          :style="{
            top: `${sig.y || 25 * scale}%`,
            left: `${sig.x || 25 * scale}%`,
            width: `${sig.width || 30 * scale}%`,
            height: `${sig.height || 10 * scale}%`,
          }"
        >
          <img
            :src="`data:image/png;base64,${sig.signature.image}`"
            :style="{
              maxWidth: '100%',
              maxHeight: '100%',
              ...getLargerDimension(sig),
            }"
          >
          <button
            v-if="showSignatureRemoveButton"
            class="buttons"
            @click="$emit('signature-removed', sig.id)"
          >
            Remove
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import pixelsInViewport from '../utils/pixelsInViewport';
import makeMoveable from '../utils/makeMoveable';
import '../scss/Viewport.scss';

export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    isPagination: {
      type: Boolean,
      default: false,
    },
    showSinglePage: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 0,
    },
    signatures: {
      type: Array,
      default: () => ([]),
    },
    signatureAreas: {
      type: Array,
      default: () => ([]),
    },
    addSignatureAreaToPage: {
      type: Number,
      default: null,
    },
    canSetSignatureArea: {
      type: Boolean,
      default: false,
    },
    canSign: {
      type: Boolean,
      default: false,
    },
    showSignatureRemoveButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rendering: [],
    signing: 1,
    currentSigArea: null,
    moveable: null,
  }),
  computed: {
    signatureAreasComputed() {
      const { signatures } = this;
      const signatureAreas = this.signatureAreas
        .map((sa) => ({ ...sa, signature: signatures.find((s) => s.id === sa.id) }));
      if (!this.addSignatureAreaToPage) return signatureAreas;
      return [
        ...this.signatureAreas,
        { id: 0, page: this.addSignatureAreaToPage },
      ];
    },
  },
  watch: {
    currentPage(pageNumber) {
      if (!this.isPagination && pageNumber) {
        const el = this.$refs[`page-${pageNumber}`][0];
        const styles = getComputedStyle(this.$el);

        this.$el.scrollTop = el.offsetTop
          - parseFloat(styles.paddingBottom)
          - parseFloat(styles.paddingTop)
          - Math.max(this.$el.parentNode.offsetHeight - this.$el.offsetHeight, 0);
      }
    },
    scale() {
      this.pages.forEach(this.renderPage);
    },
    signatureAreasComputed: {
      deep: true,
      async handler() {
        await this.cancelSignatureArea();
        if (this.addSignatureAreaToPage) {
          this.moveable = makeMoveable(this.$refs['sigArea-0'][0]);
        }
      },
    },
  },
  async mounted() {
    await Promise.all(this.pages.map(this.renderPage));
    if (!this.isPagination) {
      const { $el } = this;
      if (this.canSetSignatureArea || this.canSign) {
        const firstPage = $el.childNodes[0];
        const firstPageStyle = getComputedStyle(firstPage);
        const elStyle = getComputedStyle($el);
        const newHeight = firstPage.offsetHeight
          + parseFloat(firstPageStyle.marginTop)
          + parseFloat(firstPageStyle.marginBottom)
          + parseFloat(elStyle.paddingTop)
          + parseFloat(elStyle.paddingBottom);
        $el.style.height = `${newHeight}px`;
      }
      $el.addEventListener('scroll', this.maybeChangePage);
    }
  },
  beforeDestroy() {
    this.$el.removeEventListener('scroll', this.maybeChangePage);
  },
  methods: {
    renderPage(page) {
      const { scale } = this;
      if (this.rendering[page.pageNumber]) {
        this.rendering[page.pageNumber].promise.catch(() => {});
        this.rendering[page.pageNumber].cancel();
      }

      const canvas = this.$refs[`canvas-${page.pageNumber}`][0];
      const viewport = this.scale
        ? page.getViewport({ scale })
        : page.getViewport({ scale: canvas.width / page.getViewport({ scale: 1 }).width });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const render = page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      });
      this.rendering[page.pageNumber] = render;
      return render.promise;
    },
    maybeChangePage() {
      const pagesInView = {};
      Array.from(this.$el.childNodes).forEach((page, ix) => {
        const pxInViewport = pixelsInViewport(page);
        if (pxInViewport) {
          const pageNum = ix + 1;
          pagesInView[pageNum] = pxInViewport;
        }
      });
      const pixelValues = Object.values(pagesInView);
      if (pixelValues.length) {
        // find page with the most pixels in view
        const currentPage = Object.keys(pagesInView)
          .reduce((prev, current) => {
            const c = pagesInView[prev] > pagesInView[current] ? prev : current;
            return c;
          });
        this.$emit(
          'page-change',
          parseInt(currentPage, 10),
        );
      }
    },
    editSignatureArea(sigAreaId, e) {
      if (
        this.currentSigArea !== sigAreaId
        && !this.moveable
        && e.target.className.includes('cpv-pdf-viewport__page__signature-area')
      ) {
        if (this.moveable) this.cancelSignatureArea();
        this.currentSigArea = sigAreaId;
        const sigArea = this.$refs[`sigArea-${sigAreaId}`][0];
        this.moveable = makeMoveable(sigArea);
      }
    },
    getSignatureArea(sigArea) {
      const canvas = this.$refs[`canvas-${sigArea.page}`][0];
      return {
        id: sigArea.id,
        width: (canvas.width / 100) * sigArea.width,
        height: (canvas.height / 100) * sigArea.height,
      };
    },
    cancelSignatureArea() {
      if (this.moveable) {
        this.$emit('update:addSignatureAreaToPage', 0);
        this.moveable.target.style.removeProperty('transform');
        this.moveable.destroy();
        this.moveable = null;
        this.currentSigArea = null;
      }
    },
    deleteSignatureArea(id) {
      const { target } = this.moveable;
      this.cancelSignatureArea();
      target.remove();
      this.$emit('delete-signature-area', id);
    },
    saveSignatureArea() {
      const {
        left,
        top,
        width,
        height,
      } = this.moveable.getRect();
      const { addSignatureAreaToPage, currentSigArea } = this;
      const canvas = this.$refs[`canvas-${this.currentPage}`][0];
      this.$emit('save-signature-area', {
        id: currentSigArea,
        position: {
          page: addSignatureAreaToPage
            || this.signatureAreas.find((s) => s.id === currentSigArea).page,
          x: (left / canvas.width) * 100,
          y: (top / canvas.height) * 100,
          width: (width / canvas.width) * 100,
          height: (height / canvas.height) * 100,
        },
      });
    },
    getLargerDimension(signature) {
      const width = signature.width || 30;
      const height = signature.height || 10;
      if (height > width) return { height: '100%' };
      return { width: '100%' };
    },
  },
};
</script>
