<template>
  <pre
    v-if="loadingError"
    v-text="loadingError"
  />
  <div
    v-else
    :class="{ 'cpv-pdf-viewer--has-pagination': enablePagination }"
    class="cpv-pdf-viewer"
  >
    <template v-if="pages && pages.length">
      <div
        v-if="enablePagination"
        class="cpv-pdf-viewer__pagination"
      >
        <viewport
          v-bind="{ pages, currentPage: currentPageForPagination }"
          is-pagination
          @page-click="setCurrentPage"
        />
      </div>
      <div
        class="cpv-pdf-viewer__viewport-container"
        :class="{ 'cpv-pdf-viewer__viewport-container--has-controls': shouldShowControls }"
      >
        <div
          v-if="shouldShowControls"
          class="cpv-pdf-viewer__viewport-container__control-bar"
        >
          <template v-if="enableZoom && !canSetSignatureArea">
            <div
              :class="[
                'cpv-pdf-viewer__viewport-container__control-bar__section',
                'cpv-pdf-viewer__viewport-container__control-bar__section--right'
              ]"
            >
              Zoom:
              <button @click="scale -= zoomIncrement">
                -
              </button>
              <button @click="scale += zoomIncrement">
                +
              </button>
            </div>
          </template>
          <template v-if="canSetSignatureArea">
            <div
              :class="[
                'cpv-pdf-viewer__viewport-container__control-bar__section',
                'cpv-pdf-viewer__viewport-container__control-bar__section--right',
              ]"
            >
              <button
                :disabled="!!addingSignatureAreaToPage
                  || !(signatureAreas.length < maxSignatureAreas)"
                @click="addSignatureArea"
              >
                Add Signature Area ({{ signatureAreas.length }}/{{ maxSignatureAreas }})
              </button>
            </div>
          </template>
        </div>
        <viewport
          v-bind="{
            pages,
            currentPage,
            scale,
            signatures,
            signatureAreas,
            canSign,
            canSetSignatureArea,
            showSignatureRemoveButton,
          }"
          :add-signature-area-to-page.sync="addingSignatureAreaToPage"
          @page-change="currentPageForPagination = $event"
          @save-signature-area="$emit('save-signature-area', $event)"
          @delete-signature-area="$emit('delete-signature-area', $event)"
          @signature-removed="$emit('signature-removed', $event)"
          @signing="signing = $event"
        />
      </div>
      <overlay v-if="isLoading">
        <span class="loading" />
      </overlay>
    </template>
    <overlay v-if="canSign && signing">
      <signature
        v-bind="{ additionalData }"
        :width="signing.width"
        :height="signing.height"
        @save="saveSignature"
        @cancel="signing = null"
      />
    </overlay>
  </div>
</template>
<script>
import Viewport from './components/Viewport.vue';
import Signature from './components/Signature.vue';
import Overlay from './components/Overlay.vue';
import base64ToBinary from './utils/base64ToBinary';
import pdfjs from './lib/pdfjs';
import './scss/CompliancePdfViewer.scss';

export default {
  components: { Viewport, Signature, Overlay },
  props: {
    /** PDF file as base64 encoded string */
    base64: {
      type: String,
      default: undefined,
    },
    /** PDF file as js File object */
    file: {
      type: File,
      default: undefined,
    },
    /** Show zoom in/out buttons */
    enableZoom: {
      type: Boolean,
      default: true,
    },
    /** Refine zoom level on load */
    initialZoom: {
      type: Number,
      default: 1.0,
    },
    /** Refine level of zoom in/decrease when changed  */
    zoomIncrement: {
      type: Number,
      default: 0.2,
    },
    /** Show thumbnail navigation */
    enablePagination: {
      type: Boolean,
      default: false,
    },
    /** Enable definition of signature areas */
    canSetSignatureArea: {
      type: Boolean,
      default: false,
    },
    /** Enable signing in defined signature areas */
    canSign: {
      type: Boolean,
      default: false,
    },
    /** Existing signatures */
    signatures: {
      type: Array,
      default: () => ([]),
    },
    /** Existing signature areas */
    signatureAreas: {
      type: Array,
      default: () => ([]),
    },
    /* Limit the number of definable signature areas */
    maxSignatureAreas: {
      type: Number,
      default: 2,
    },
    /** Show loading spinner over PDF */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /** Show button to remove existing signature */
    showSignatureRemoveButton: {
      type: Boolean,
      default: true,
    },
    /* Allow misc data to be easily added without resulting in excessive props */
    additionalData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (vm) => ({
    totalPages: 0,
    loadingError: null,
    pages: [],
    currentPage: 1,
    currentPageForPagination: 1,
    process,
    scale: vm.initialZoom,
    addingSignatureAreaToPage: 0,
    signing: null,
  }),
  computed: {
    shouldShowControls() {
      return this.enableZoom || this.canSetSignatureArea;
    },
  },
  watch: {
    base64: {
      immediate: true,
      handler(base64) {
        if (base64) {
          this.initPdf(base64ToBinary(base64));
        }
      },
    },
    file: {
      immediate: true,
      handler(file) {
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const typedarray = new Uint8Array(reader.result);
            this.initPdf(typedarray);
          };
          reader.readAsArrayBuffer(file);
        }
      },
    },
  },
  methods: {
    initPdf(data) {
      try {
        if (!data) {
          throw new Error('Please ensure a PDF is provided as a File or base64 encoded string');
        }
        this.loadPDF(data);
      } catch (e) {
        this.loadingError = e;
      }
    },
    async loadPDF(file) {
      this.pages = null;
      const pdf = await pdfjs.getDocument(file).promise;
      this.totalPages = pdf.numPages;
      this.pages = await Promise.all([...Array(this.totalPages).keys()].map(async (ix) => {
        const page = await pdf.getPage(ix + 1);
        return page;
      }));
    },
    setCurrentPage(pageNumber) {
      if (!this.addingSignatureAreaToPage) {
        this.currentPage = pageNumber;
        this.currentPageForPagination = pageNumber;
      }
    },
    addSignatureArea() {
      this.currentPage = this.currentPageForPagination;
      this.addingSignatureAreaToPage = this.currentPageForPagination;
    },
    saveSignature(e) {
      this.$emit('signed', {
        id: this.signing.id,
        ...e,
      });
      this.signing = null;
    },
  },
};
</script>
