<template>
  <div class="signature">
    <template v-if="additionalData.legal_disclaimer">
      <div class="signature__disclaimer">
        <span>
          Please read the
          <a
            :href="additionalData.legal_disclaimer.document_url"
            target="_blank"
            rel="noopener noreferrer"
          >Electronic Record and Signature Disclosure</a>.
        </span>
        <input
          id="signature-disclaimer"
          v-model="disclaimerConfirmed"
          type="checkbox"
        >
        <label
          for="signature-disclaimer"
          v-text="additionalData.legal_disclaimer.banner_label"
        />
      </div>
    </template>
    <div class="signature__name-field">
      <label>
        Full name
        <input
          v-model="signatureName"
          type="text"
        >
      </label>
    </div>
    <ul class="signature__nav signature__nav--tabs">
      <li
        class="signature__nav__item"
        :class="{ active: mode === modes.AUTO }"
        @click.prevent="mode = modes.AUTO"
      >
        Adopt Signature
      </li>
      <li
        v-if="canSignManually || tabletAvailable"
        class="signature__nav__item"
        :class="{ active: mode === modes.DRAWING }"
        @click.prevent="mode = modes.DRAWING"
      >
        Sign Manually
      </li>
    </ul>
    <canvas
      :ref="`signature-${_uid}`"
      :key="`signature-${_uid}`"
      class="signature__canvas"
      :class="{ active }"
      @touchstart="handleEvent"
      @touchmove.prevent="handleEvent"
    />
    <ul
      v-if="mode === modes.AUTO"
      class="signature__nav signature__nav--pills"
    >
      <li
        v-for="(font, ix) in FONTS"
        :key="`font-${ix}`"
        class="signature__nav__item"
        :class="{ active: fontFamily === ix }"
        :style="`font-family: ${font};`"
        @click.prevent="fontFamily = ix"
      >
        {{ `Version ${ix + 1}` }}
      </li>
    </ul>
    <div class="signature__actions">
      <button
        class="cancel"
        @click="$emit('cancel')"
        v-text="'Cancel'"
      />
      <button
        class="save"
        :disabled="additionalData.legal_disclaimer && !disclaimerConfirmed"
        @click="saveSignature"
        v-text="'Save'"
      />
    </div>
  </div>
</template>
<script>
import formatDate from 'date-fns/format';
import fontLoader from 'webfontloader';
import SigWebTablet from '../lib/SigWebTabletNew';
import '../scss/Signature.scss';

const modes = Object.freeze({
  DRAWING: 0,
  AUTO: 1,
});

const FONTS = [
  'Allura',
  'Caveat',
  'Herr Von Muellerhoff',
  'Satisfy',
  'Yellowtail',
];

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    additionalData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      FONTS,
      active: false,
      canvas: null,
      ctx: null,
      disclaimerConfirmed: false,
      fontFamily: 0,
      fontSize: 60,
      gutter: 16,
      isLoading: false,
      manualSign: true,
      mode: modes.AUTO,
      modes,
      mouse: {
        down: false,
        previous: {
          x: null,
          y: null,
        },
      },
      signatureName: null,
      sigWeb: null,
      tabletAvailable: false,
      tabletConfirmed: false,
      tmr: null,
    };
  },
  computed: {
    signatureInitials() {
      return this.signatureName
        .split(' ')
        .map((segment) => segment[0]).join('')
        .toUpperCase();
    },
    canSignManually() {
      return 'ontouchstart' in window;
    },
  },
  watch: {
    fontFamily() {
      this.writeName();
    },
    signatureName: {
      handler() {
        if (this.mode === modes.AUTO) this.writeName();
      },
    },
    mode(mode) {
      this.clearCanvas();
      if (mode === modes.AUTO) {
        this.writeName();
      } else {
        if (!this.sigWeb) {
          this.sigWeb = new SigWebTablet(this.ctx);
        }
        if (this.tabletConfirmed) {
          try {
            this.sigWeb.SetDisplayXSize(this.canvas.width);
            this.sigWeb.SetDisplayYSize(this.canvas.height);
            this.sigWeb.SetTabletState(0);
            this.sigWeb.SetJustifyMode(0);
            this.sigWeb.ClearTablet();
            if (this.tmr === null) {
              this.tmr = this.sigWeb.SetTabletState(1, 50);
            } else {
              this.sigWeb.SetTabletState(0);
              this.tmr = null;
              this.tmr = this.sigWeb.SetTabletState(1, 50);
            }
          } catch (e) {
            this.tabletAvailable = false;
          } finally {
            this.tabletConfirmed = true;
          }
        }
      }
    },
  },
  mounted() {
    this.loadFonts();
    this.initCanvas();
    window.addEventListener('resize', this.initCanvas);
    try {
      this.sigWeb = new SigWebTablet(this.ctx);
      this.sigWeb.SetDisplayXSize(this.canvas.width);
      this.tabletAvailable = true;
    } catch {
      this.tabletAvailable = false;
    } finally {
      this.tabletConfirmed = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initCanvas);
  },
  methods: {
    handleEvent(event) {
      if (this.mode === modes.DRAWING) {
        const { draw, mouse, $set } = this;
        switch (event.type) {
          case 'touchstart':
          case 'mousedown':
            window.document.body.addEventListener(
              'mouseup',
              () => $set(mouse, 'down', false),
              {
                capture: true,
                passive: true,
                once: true,
              },
            );
            window.document.body.addEventListener(
              'touchend',
              () => $set(mouse, 'down', false),
              {
                capture: true,
                passive: true,
                once: true,
              },
            );
            $set(mouse, 'previous', { x: null, y: null });
            $set(mouse, 'down', true);
            break;
          case 'touchmove':
          case 'mousemove':
            draw(event);
            break;
          case 'mouseenter':
            if (mouse.down) {
              $set(mouse, 'previous', { x: null, y: null });
            }
            break;
          default:
        }
      }
    },
    draw(event) {
      const { canvas, ctx } = this;
      const rect = canvas.getBoundingClientRect();
      const x = (event.clientX || Object.values(event.targetTouches)[0].clientX) - rect.left;
      const y = (event.clientY || Object.values(event.targetTouches)[0].clientY) - rect.top;
      const coords = { x, y };

      if (this.mouse.down) {
        ctx.beginPath();
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 2;
        if (this.mouse.previous.x) {
          ctx.moveTo(this.mouse.previous.x, this.mouse.previous.y);
        }
        ctx.lineTo(coords.x, coords.y);
        ctx.closePath();
        ctx.stroke();
        this.signed = true;
      }
      this.$set(this.mouse, 'previous', { x: coords.x, y: coords.y });
    },
    loadFonts() {
      fontLoader.load({
        google: {
          families: FONTS,
        },
      });
    },
    initCanvas() {
      const canvas = this.$refs[`signature-${this._uid}`]; // eslint-disable-line no-underscore-dangle
      if (canvas) {
        const aspectRatio = this.width && this.height
          ? this.height / this.width
          : 0.5;
        canvas.height = canvas.clientWidth * aspectRatio;
        const ctx = canvas.getContext('2d');
        this.canvas = canvas;

        this.ctx = ctx;
        this.resizeCanvas();
        if (this.mode === modes.AUTO) {
          this.writeName();
        }
      }
    },
    resizeCanvas() {
      this.canvas.width = this.canvas.clientWidth;
      this.canvas.height = this.canvas.clientHeight;
    },
    clearCanvas() {
      this.resizeCanvas();
      const { canvas, ctx } = this;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    setCanvasFont() {
      const {
        canvas,
        ctx,
        fontSize,
        gutter,
        signatureInitials,
        signatureName,
      } = this;

      ctx.font = `${fontSize}px ${FONTS[this.fontFamily]}`;
      let textWidth = ctx.measureText(signatureName).width
        + ctx.measureText(signatureInitials).width
        + gutter * 4;
      if (textWidth > canvas.width) {
        let previousFont;
        for (let i = fontSize - 1; textWidth > canvas.width; i -= 1) {
          previousFont = ctx.font;
          ctx.font = `${i}px ${FONTS[this.fontFamily]}`;
          if (ctx.font === previousFont) break;
          textWidth = ctx.measureText(signatureName).width
            + ctx.measureText(signatureInitials).width
            + gutter * 4;
        }
      }
    },
    writeName() {
      const { canvas, ctx } = this;
      this.clearCanvas();
      if (this.signatureName) {
        this.setCanvasFont();
        this.ctx.textAlign = 'left';
        ctx.fillText(
          this.signatureName,
          this.gutter,
          (canvas.height / 2) + (this.fontSize / 4),
        );
        this.ctx.textAlign = 'right';
        ctx.fillText(
          this.signatureInitials,
          canvas.width - (this.gutter * 2),
          (canvas.height / 2) + (this.fontSize / 4),
        );
      }
    },
    writeIp() {
      const ip = this.additionalData.current_ip;
      const fontSize = 14;
      this.ctx.font = `${fontSize}px monospace`;
      this.ctx.textAlign = 'right';
      const pos = {
        x: this.canvas.width - this.gutter,
        y: this.canvas.height - this.gutter,
        width: this.canvas.width - (2 * this.gutter),
      };
      if (ip) this.ctx.fillText(ip, pos.x, pos.y - fontSize, pos.width);
      this.ctx.fillText(formatDate(new Date(), 'HH:mm dd/MM/yyyy'), pos.x, pos.y, pos.width);
    },
    saveSignature() {
      this.writeIp();
      const dataURL = this.canvas.toDataURL('image/png');
      this.$emit('save', {
        name: this.signatureName,
        image: dataURL.substring(dataURL.indexOf(',') + 1),
        ...(this.additionalData.legal_disclaimer
          ? { legal_disclaimer_id: this.additionalData.legal_disclaimer.id }
          : {}
        ),
      });
    },
  },
};
</script>
