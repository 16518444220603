<template>
  <div class="pdf-viewer-overlay">
    <div class="pdf-viewer-overlay__content">
      <slot />
    </div>
  </div>
</template>
<script>
import '../scss/Overlay.scss';

export default {};
</script>
